<template>
  <div id="detail-project">
    <Header
      :user="loggedUser"
      :currentRouteName="$route.name"
    />
    <b-container class="detail-project-layout">
      <b-row>
        <b-col>
          <h4 class="text-left">Detalle de proyecto</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-row>
            <b-col class="d-none d-sm-block">
              <BusinessInfoCard
                :loggedUser="loggedUser"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-none d-sm-block">
              <b-card class="side-card">
                <MenuItem
                  title="Detalle de proyecto"
                  icon="booking.svg"
                  :class="{ 'active' : displayedCard === 'edit-project'}"
                  @onclickMenuItem="goToPosition('edit-project')"
                />
                <div class="d-flex parent-menu" v-if="adminPermission">
                  <img
                    class="MenuItem-image"
                    :src="require('@/assets/icons/empty-user.svg')"
                  >
                  <span class="text-l ml-1 MenuItem-link">
                    Booking de técnicos
                  </span>
                </div>
                <div class="sub-menu" v-if="adminPermission">
                  <MenuItem
                    title="Abiertos"
                    icon="booking-opened.svg"
                    :class="{ 'active' : displayedCard === 'opened-technician-booking'}"
                    @onclickMenuItem="goToPosition('opened-technician-booking')"
                  />
                  <MenuItem
                    title="Cerrados"
                    icon="booking-closed.svg"
                    :class="{ 'active' : displayedCard === 'closed-technician-booking'}"
                    @onclickMenuItem="goToPosition('closed-technician-booking')"
                  />
                  <MenuItem
                    title="Vencidos"
                    icon="booking-expired.svg"
                    :class="{ 'active' : displayedCard === 'expired-technician-booking'}"
                    @onclickMenuItem="goToPosition('expired-technician-booking')"
                  />
                </div>
                <div class="d-flex parent-menu" v-if="adminPermission">
                  <img
                    class="MenuItem-image"
                    :src="require('@/assets/icons/speaker.svg')"
                  >
                  <span class="text-l ml-1 MenuItem-link">
                    Booking de hardware
                  </span>
                </div>
               <div class="sub-menu" v-if="adminPermission">
                  <MenuItem
                    title="Abiertos"
                    icon="booking-opened.svg"
                    :class="{ 'active' : displayedCard === 'opened-hardware-booking'}"
                    @onclickMenuItem="goToPosition('opened-hardware-booking')"
                  />
                  <MenuItem
                    title="Cerrados"
                    icon="booking-closed.svg"
                    :class="{ 'active' : displayedCard === 'closed-hardware-booking'}"
                    @onclickMenuItem="goToPosition('closed-hardware-booking')"
                  />
                  <MenuItem
                    title="Vencidos"
                    icon="booking-expired.svg"
                    :class="{ 'active' : displayedCard === 'expired-hardware-booking'}"
                    @onclickMenuItem="goToPosition('expired-hardware-booking')"
                  />
                </div>
                <MenuItem
                  v-if="adminPermission"
                  title="Venue"
                  icon="icon-map-pin.svg"
                  :class="{ 'active' : displayedCard === 'detail-venue'}"
                  @onclickMenuItem="goToDetailVenue()"
                />
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <slot />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Header from '@/components/Header/Header.vue'
import BusinessInfoCard from '@/components/Business/Card/BusinessInfo/BusinessInfoCard.vue'
import MenuItem from '@/components/Config/MenuItem'
import Profiles from '@/utils/getProfiles'

export default {
  name: 'ProjectLayout',
  components: {
    Header,
    BusinessInfoCard,
    MenuItem
  },
  props: {
    displayedCard: {
      type: String,
      required: true,
      default: 'edit-project'
    }
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    },
    adminPermission () {
      return this.$store.state.currentProfile === Profiles.PROFILE_BUSINESS
    },
    ...mapGetters({ project: 'project/current' })
  },
  methods: {
    goToPosition (pageName) {
      this.$router.push({
        name: pageName,
        params: {
          id: this.$route.params.id
        }
      })
    },
    goToDetailVenue () {
      this.$router.push({
        name: 'detail-venue',
        params: {
          projectId: this.$route.params.projectId,
          venueId: this.project.venue.id
        }
      })
    }
  }
}
</script>

<style lang="less">
@import 'projectLayout.less';
</style>

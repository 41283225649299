<template>
  <div class="MenuItem d-none d-sm-flex">
    <div class="d-flex">
      <img
        class="MenuItem-image"
        :alt="title"
        :src="require('@/assets/icons/' + icon)"
      >
      <span class="text-l ml-1 MenuItem-link" @click="$emit('onclickMenuItem')">
        {{ title }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    onclickMenuItem: String
  }
}
</script>

<style lang="less">
@import "menuItem";
</style>

<template>
  <div class="project-info-card">
    <b-card
      :img-src="cover"
      class="side-card d-none d-md-block"
    >
      <b-row>
        <b-col>
          <div class="avatar-container">
            <img :src="avatar" class="img-fluid">
          </div>
        </b-col>
        <b-col class="text-right">
          <AvailableBadge available />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h4>{{ loggedUserBusiness.name }}</h4>
          <div>{{ loggedUserBusiness.web }}</div>
          <div class="location">
            <img :src="require('@/assets/icons/icon-map-pin.svg')" width="16" />
            {{ loggedUserBusiness.location.administrative }}, {{ loggedUserBusiness.location.name }}
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import User from '@/domain/models/User'
import AvailableBadge from '@/components/AvailableBadge/AvailableBadge.vue'

export default {
  name: 'BusinessInfoCard',
  components: { AvailableBadge },
  props: {
    loggedUser: {
      type: User,
      required: true
    }
  },
  data () {
    return {
      loggedUserBusiness: this.loggedUser.business,
      baseUrl: process.env.VUE_APP_IMAGE_API_URL
    }
  },
  computed: {
    cover () {
      if (this.loggedUserBusiness.hasCover) {
        return this.baseUrl + this.loggedUserBusiness.getCover.path
      }
      return require('@/assets/img/profile-background.png')
    },
    avatar () {
      if (this.loggedUserBusiness.hasAvatar) {
        return this.baseUrl + this.loggedUserBusiness.getAvatar.path
      }
      return require('@/assets/icons/user.svg')
    }
  }
}
</script>

<style lang="less" scoped>
  @import 'businessInfoCard.less';
</style>

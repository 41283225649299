<template>
  <gmap-autocomplete
    class="gmap-autocomplete form-control"
    :value="value"
    :class="hasError"
    :placeholder="placeholder"
    :component-restrictions="{ country: 'es' }"
    :types="['(regions)']"
    :options="{ fields: ['address_components', 'geometry', 'place_id'] }"
    @place_changed="$emit('selected', $event)"
    @keyup="$emit('keypress')"
  >
  </gmap-autocomplete>
</template>

<script>
export default {
  name: 'GooglePlaces',
  emits: ['selected', 'keypress'],
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'Selecciona la ciudad'
    },
    state: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {}
  },
  computed: {
    hasError () {
      if (this.state) {
        return ''
      }

      return 'is-invalid'
    }
  }
}
</script>

<style lang="less" scoped>
  @import 'GooglePlaces.less';
</style>

<style lang="less">
  @import "../../style/colors";
  .pac-container {
    background-color: @color_dark_purple;
    z-index: 10000 !important;
    &:after {
      background-image: none;
      height: 0;
    }
  }
  .pac-icon {
  }
  .pac-item {
    color: @color_white;
    border: none;
    padding: 3px 15px;
    &:hover {
      color: @color_dark_blue_light;
      background-color: @color_radiant_blue;
      * {
        color: @color_dark_blue_light
      }
    }
    &-query {
      color: @color_white;
      padding-right: 1rem;
    }
  }
</style>

import Location from '../models/Location'

class LocationFactory {
  getLocation (locationData) {
    const location = {
      county: locationData.county,
      name: locationData.name,
      administrative: locationData.administrative,
      coordinates: locationData.coordinates,
      country: locationData.country,
      countryCode: locationData.countryCode,
      service: locationData.service
    }
    return new Location(location)
  }

  getLocationByAlgolia (locationData) {
    const location = {
      county: locationData.hit.county[0],
      name: locationData.hit.locale_names[0],
      administrative: locationData.hit.administrative[0],
      coordinates: {
        latitude: locationData.hit._geoloc.lat,
        longitude: locationData.hit._geoloc.lng
      },
      country: locationData.hit.country,
      countryCode: locationData.hit.country_code,
      service: {
        name: 'algolia',
        externalId: locationData.hit.objectID
      }
    }
    return new Location(location)
  }

  getLocationByGoogle (locationData) {
    const country = this.getValueByType('country', locationData.address_components)
    const autonomy = this.getValueByType('administrative_area_level_1', locationData.address_components)
    const region = this.getValueByType('locality', locationData.address_components)
    const county = this.getValueByType('administrative_area_level_2', locationData.address_components)
    const location = {
      county: county ? county.long_name : '',
      name: region ? region.long_name : '',
      administrative: autonomy ? autonomy.long_name : '',
      coordinates: {
        latitude: locationData.geometry.location.lat(),
        longitude: locationData.geometry.location.lng()
      },
      country: country.long_name,
      countryCode: country.short_name,
      service: {
        name: 'google',
        externalId: locationData.place_id
      }
    }
    return new Location(location)
  }

  getValueByType (type, addressComponents) {
    for (const element of addressComponents) {
      if (element.types.indexOf(type) > -1) {
        return element
      }
    }

    return {}
  }
}

export default new LocationFactory()

<template>
  <b-modal
    :id="id"
    title="¿Descartar?"
    centered
    modal-class="avify-confirmation-close-modal"
    :hide-header-close="true"
    size="xs"
    @ok="confirmationCloseModalConfirmed"
  >
    ¿Seguro que quieres descartar los cambios?
    <template v-slot:modal-footer="{ ok, close }">
      <div class="w-100">
        <b-button variant="primary" size="xl" class="float-right" @click="close()">
          No, gracias
        </b-button>
        <b-button variant="secondary" size="xl" class="float-left" @click="ok()">
          Descartar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ConfirmationCloseModal',
  props: {
    id: {
      type: String,
      default: ''
    },
    parentModalId: {
      type: String,
      required: true
    }
  },
  methods: {
    confirmationCloseModalConfirmed () {
      this.$bvModal.hide(this.parentModalId)
    }
  }
}
</script>

<style lang="less">
  @import "../../style/text";

  * {
    font-family: @font_primary;
  }

  .avify-confirmation-close-modal {
    .modal-xs {
      @media (min-width: 576px) {
        max-width: 400px;
      }
    }

    .modal-content {
      background-color: #31395A;
      border-bottom: none;
    }

    .modal-header {
      border-bottom: none;
      justify-content: center;
    }

    .modal-title {
      color: white;
      font-size: 14px;
      @media (min-width: 576px) {
        font-size: 20px;
      }
    }

    .modal-body {
      text-align: center;
      color: white;
    }

    .modal-footer {
      border-top: none;
    }

  }
</style>

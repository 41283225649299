<template>
  <validation-observer ref="technicianBookingForm" v-slot="{ handleSubmit }">
    <b-form v-if="booking" @submit.stop.prevent="handleSubmit(checkForm)" autocomplete="off" novalidate>
      <b-row>
        <b-col>
          <validation-provider
            vid="name"
            name="nombre"
            rules="required|max:255"
            v-slot="validationContext"
          >
            <b-form-group id="name" label-for="input-name">
              <b-form-input
                id="name"
                name="name"
                :state="getValidationState(validationContext)"
                placeholder="Nombre del booking"
                v-model="booking.name"
              ></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            vid="code"
            name="código"
            rules="max:8"
            v-slot="validationContext"
          >
            <b-form-group id="code" label-for="input-code">
              <b-form-input
                id="code"
                name="code"
                :state="getValidationState(validationContext)"
                placeholder="ID del booking"
                v-model="booking.code"
              ></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-form-group id="createdBy" label-for="input-created-by">
            <b-form-input
              id="createdBy"
              name="createdBy"
              placeholder="Creado por"
              readonly
              v-model="booking.createdBy"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <h5>Detalles del evento</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <v-date-picker
            v-model="dateRange"
            color="indigo"
            is-dark
            is-range
            is-expanded
            locale="ES-es"
            :popover="{ placement: 'auto' }"
            @input="dateSelected"
            mode="dateTime"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <b-row>
                <b-col>
                  <validation-provider
                    vid="startDate"
                    name="fecha de inicio"
                    rules="required"
                    v-slot="validationContext"
                  >
                    <b-form-group id="eventStartAt" label-for="input-event-start-at">
                      <b-form-input
                        id="startDate"
                        name="start-date"
                        placeholder="Fecha inicio"
                        :state="getValidationState(validationContext)"
                        readonly
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                      />
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider
                    vid="endDate"
                    name="fecha de final"
                    rules="required|todayOn"
                    v-slot="validationContext"
                  >
                    <b-form-group id="eventEndAt" label-for="input-event-end-at">
                      <b-form-input
                        id="endDate"
                        name="end-date"
                        placeholder="Fecha final"
                        readonly
                        :state="getValidationState(validationContext)"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                      />
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </template>
          </v-date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            vid="eventType"
            name="tipo de evento"
            rules="required"
            v-slot="validationContext"
          >
            <b-form-group id="eventType" label-for="input-event-type">
              <b-form-select
                v-model="booking.eventType"
                :options="eventTypeOptions"
                :state="getValidationState(validationContext)"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                  >
                    Tipo de evento
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
            <validation-provider name="ciudad"
              v-slot="validationContext"
              vid="city"
              rules="required">
              <b-form-group id="city" label-for="input-city">
                  <GooglePlaces
                    :value="value"
                    @selected="onChangeGoogleData"
                    @keypress="onKeyPressGoogle"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h6>Documentación adicional</h6>
          <b-form-group id="docs" label-for="input-docs">
            <b-form-checkbox-group
              class="avify-checkbox filter"
              id="docs-group"
              v-model="booking.docs"
              :options="docOptions"
              name="docs"
            ></b-form-checkbox-group>
          </b-form-group>
          <b-form-input
            id="docExtra"
            name="docExtra"
            placeholder="Documentación extra"
            v-model="booking.docExtra"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="catering">
          <b-form-checkbox
            id="catering"
            class="avify-checkbox filter"
            v-model="booking.catering"
            name="catering"
            :value="true"
            :unchecked-value="false"
          >
            Catering incluído
          </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import LocationFactory from '@/domain/services/LocationFactory'
import GooglePlaces from '@/components/Places/GooglePlaces.vue'

export default {
  name: 'TechnicianBookingForm',
  components: { GooglePlaces },
  props: {
    form_prop: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      booking: this.form_prop,
      value: this.form_prop.getCompleteLocation,
      errorFormMessage: null,
      selectedCity: null,
      dateRange: {
        start: this.form_prop.getEventStartAt ? this.form_prop.getEventStartAt : null,
        end: this.form_prop.getEventEndAt ? this.form_prop.getEventEndAt : null
      }
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    eventTypeOptions () {
      return this.$store.getters.bookingEventTypes.map(elem => {
        return {
          text: elem.name,
          value: elem
        }
      })
    },
    docOptions () {
      return this.$store.getters.bookingDocs.map(elem => {
        return {
          text: elem.name,
          value: elem
        }
      })
    }
  },
  watch: {
    form_prop (value) {
      this.booking = value
      this.dateRange = {
        start: value.startAt,
        end: value.endAt
      }
      this.value = value.getCompleteLocation
    }
  },
  methods: {
    dateSelected (date) {
      this.booking.eventStartAt = this.formatDate(date.start)
      this.booking.eventEndAt = this.formatDate(date.end)
    },
    formatDate (date) {
      return date.getFullYear() + '-' +
        this.lpad(date.getMonth() + 1) + '-' +
        this.lpad(date.getDate()) + ' ' +
        this.lpad(date.getHours()) + ':' +
        this.lpad(date.getMinutes()) + ':' +
        this.lpad(date.getSeconds())
    },
    lpad (value) {
      return String(value).padStart(2, '0')
    },
    getValidationCityState (obj) {
      if (!obj.validated) {
        return true
      }

      if (this.value === '' || this.value === null) {
        return false
      }

      return true
    },
    onChangeGoogleData (evt) {
      const location = LocationFactory.getLocationByGoogle(evt)
      this.selectedCity = evt
      this.value = this.getFormattedGoogleLocationFromHit(location)
      this.booking.location = location
    },
    getFormattedGoogleLocationFromHit (location) {
      return [location.name, location.administrative, location.country].join(', ')
    },
    onKeyPressGoogle () {
      this.selectedCity = null
      this.booking.location = null
      this.value = ''
    },
    loadData () {
      this.$store.dispatch('getBookingEventTypes')
      this.$store.dispatch('getBookingDocs')
    },
    cleanSearch (text, inputText) {
      const cleanText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
      const cleanInput = inputText.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
      return cleanText.includes(cleanInput)
    }
  }
}
</script>
<style lang="less" scoped>
@import 'technicianBookingForm';
</style>

<template>
  <b-modal
    :id="id"
    :title="title"
    :size="size"
    :ok-title="okTitle"
    :confirmationClose="confirmationClose"
    centered
    modal-class="avify-modal"
    dialog-class="m-0 m-sm-auto"
    content-class=""
    :body-class="modalBodyClasses"
    @ok="emitConfirmed"
    @cancel="deleteItem"
    @close="closeModal"
    header-class="flex-row-reverse flex-md-row align-items-center"
    :footer-class="modalFooterClasses"
    :no-close-on-backdrop=true
    :scrollable="scrollable"
    :hide-footer="hideFooter"
    @show="$emit('show')"
  >
    <template v-slot:modal-header="{ ok, close }">
      <!-- Emulate built in modal header close button action -->
      <b-button size="sm" variant="link" class="d-block d-sm-none" @click="ok()" v-if="!hideTop">
        GUARDAR
      </b-button>
      <div class="d-flex flex-row-reverse flex-sm-row justify-content-end justify-content-sm-between align-items-center w-100">
        <h5 class="modal-title pl-1 pl-sm-0">{{ title }}</h5>
        <span class="close" @click="close()">
          <img src="@/components/Modal/close-modal.svg">
        </span>
      </div>
    </template>
    <slot></slot>
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button variant="secondary" @click="cancel()" v-if="deleteButton">
        <b-icon-trash v-if="deleteText==''" />
        <span v-else>{{ deleteText }}</span>
      </b-button>
      <b-button variant="primary" @click="ok()">
        {{ okTitle }}
      </b-button>
    </template>
    <ConfirmationCloseModal
      id="confirmation-close-modal"
      :parentModalId="id"/>
    <AskConfirmationModal
      id="ask-confirmation-modal"
      :parentModalId="id"
      :title="askConfirmationTitle"
      :body="askConfirmationText"
      @confirm="askConfirmFunction"
    />
  </b-modal>
</template>

<script>
import { BIconTrash } from 'bootstrap-vue'
import ConfirmationCloseModal from './ConfirmationCloseModal'
import AskConfirmationModal from './AskConfirmationModal'

export default {
  name: 'ModalComponent',
  components: {
    AskConfirmationModal,
    ConfirmationCloseModal,
    BIconTrash
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'sm'
    },
    okTitle: {
      type: String,
      required: false,
      default: 'OK'
    },
    deleteButton: {
      type: Boolean,
      required: false,
      default: false
    },
    deleteText: {
      type: String,
      required: false,
      default: ''
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: false
    },
    confirmationClose: {
      type: Boolean,
      required: false,
      default: true
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: true
    },
    hideTop: {
      type: Boolean,
      required: false,
      default: false
    },
    hideFooter: {
      type: Boolean,
      required: false,
      default: false
    },
    askConfirmation: {
      type: Boolean,
      required: false,
      default: false
    },
    askConfirmationTitle: {
      type: String,
      required: false,
      default: 'Confirmación'
    },
    askConfirmationText: {
      type: String,
      required: false,
      default: '¿Seguro que quieres realizar esta accion?'
    },
    validation: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    modalFooterClasses: function () {
      let classes = 'd-none d-sm-flex '

      if (this.deleteButton) {
        classes += 'justify-content-sm-between'
      }

      return classes
    },
    modalBodyClasses: function () {
      if (this.noPadding) {
        return 'p-0'
      }

      return ''
    }
  },
  methods: {
    emitConfirmed (bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.askConfirmation && this.validation) {
        this.$bvModal.show('ask-confirmation-modal')
        return
      }
      this.$emit('confirmed', this.id)
    },
    askConfirmFunction () {
      this.$emit('askConfirmationFunction', this.id)
    },
    deleteItem (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$emit('deleted', this.id)
    },
    closeModal (bvModalEvt) {
      if (!this.confirmationClose) {
        return
      }
      bvModalEvt.preventDefault()
      this.$bvModal.show('confirmation-close-modal')
    }
  }
}
</script>

<style lang="less">
  @import "modalComponent.less";
</style>

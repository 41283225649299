<template>
  <ModalComponent
    id="booking-modal"
    title="Crear booking"
    ok-title="Siguiente"
    size="lg"
    @confirmed="onCreateBooking"
    @show="onShow()"
  >
    <TechnicianBookingForm
      ref="technicianBookingForm"
      :form_prop.sync="form"
    />
  </ModalComponent>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import TechnicianBookingForm from '@/components/Business/Project/TechnicianBooking/Form/TechnicianBookingForm.vue'
import TechnicianBookingFactory from '@/domain/services/TechnicianBookingFactory'

export default {
  name: 'CreateTechnicianBookingModal',
  components: {
    ModalComponent,
    TechnicianBookingForm
  },
  data () {
    return {
      form: this.form_prop
    }
  },
  computed: {
    ...mapGetters({ technicianBooking: 'technicianBooking/current' })
  },
  methods: {
    ...mapActions('technicianBooking', ['setEmpty']),
    onShow () {
      this.setEmpty()
      this.form = TechnicianBookingFactory.get(this.technicianBooking)
    },
    async onCreateBooking () {
      const valid = await this.$refs.technicianBookingForm.$refs.technicianBookingForm.validate()
      if (!valid) {
        return
      }
      const payload = {
        projectId: this.$route.params.projectId,
        data: this.form
      }
      this.$store.dispatch('technicianBooking/create', payload)
        .then(() => {
          this.$bvModal.hide('booking-modal')
          this.$store.dispatch('showConfirmationModal')
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import 'createTechnicianBookingModal';
</style>

export default class Location {
  constructor (locationData) {
    this.administrative = locationData.administrative
    this.coordinates = locationData.coordinates
    this.country = locationData.country
    this.countryCode = locationData.countryCode
    this.county = locationData.county
    this.name = locationData.name
    this.service = locationData.service
  }

  get getAlgoliaId () {
    return this.service.externalId
  }

  get getCoordinates () {
    return this.coordinates
  }

  get getLatitude () {
    return this.coordinates.latitude
  }

  get getLongitude () {
    return this.coordinates.longitude
  }
}

<template>
  <b-modal
    :id="id"
    hide-header
    centered
    modal-class="avify-confirmation-close-modal"
    :hide-header-close="true"
    size="xs"
    @ok="confirmationCloseModalConfirmed"
  >
    <h4>{{ title }}</h4>
    {{ body }}
    <template v-slot:modal-footer="{ ok, close }">
      <div class="w-100 buttons">
        <b-button variant="secondary" size="xl" @click="close()">
          No
        </b-button>
        <b-button variant="primary" size="xl" @click="ok()">
          Si
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'AskConfirmationModal',
  props: {
    id: {
      type: String,
      default: ''
    },
    parentModalId: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false,
      default: 'Confirmación'
    },
    body: {
      type: String,
      required: false,
      default: '¿Seguro que quieres realizar esta accion?'
    }
  },
  methods: {
    confirmationCloseModalConfirmed () {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="less" scoped>
  @import "../../style/text";

  header {
    padding: 0;
  }

  h4 {
    margin-bottom: 2rem;
  }

  * {
    font-family: @font_primary;
  }

  .avify-confirmation-close-modal {
    .modal-xs {
      @media (min-width: 576px) {
        max-width: 400px;
      }
    }

    .modal-content {
      background-color: #31395A;
      border-bottom: none;
    }

    .modal-header {
      border-bottom: none;
      justify-content: center;
    }

    .modal-title {
      color: white;
      font-size: 14px;
      @media (min-width: 576px) {
        font-size: 20px;
      }
    }

    .modal-body {
      text-align: center;
      color: white;
    }

    .modal-footer {
      border-top: none;
    }

    .buttons {
      display: flex;
      justify-content: center;
      > button {
        margin-left: 1rem;
        margin-right: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
</style>
